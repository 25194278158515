import { mapGetter } from 'CommonUtils/store/state.js';
import operations from "CommonUtils/operations/uniqBy";

export default function useBadgeText(props, labelProp) {
  const customization = mapGetter('page/getLayoutOptions');
  const { badgePromotions, promosPerProduct  } = toRefs(props);
  const nShowPromotions = computed(() => customization?.value?.promos_per_product || promosPerProduct?.value || 1);
  const giftsWithStock = mapGetter('page/getGiftsWithStock');
  
  const filteredSortedPromos = computed(() => {
    const promos = badgePromotions.value;

    if (!Array.isArray(promos)) {
      return [];
    }
    const filteredPromos = promos.filter(promo => {
      const hasRequiredProps = promo[labelProp] && promo.priority;
      const hasGiftProductsOutOfStock = promo.target_references?.length &&
        !promo.target_references.some(reference => giftsWithStock.value.includes(reference));

        return hasRequiredProps && !hasGiftProductsOutOfStock;
    });
  
    const sortedPromos = filteredPromos.sort((promoA, promoB) => promoA.priority - promoB.priority);
    const uniquePromos = operations.uniqBy(sortedPromos, labelProp);
  
    return uniquePromos;
  });
  const hasSeveralPrincipalPromos = computed(() => {
    return filteredSortedPromos.value?.length > nShowPromotions.value;
  });

  const promotionsDisplayed = computed(() => {
    return filteredSortedPromos.value?.slice(0, nShowPromotions.value);
  });

  return {
    hasSeveralPrincipalPromos,
    promotionsDisplayed,
  };
}
